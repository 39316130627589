import { ObjectId } from '@freightview/object-id'

import {
  vArray,
  vEmail,
  vObject,
  vObjectId,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { UserPermissionType, userPermissionTypes } from './common'
import { User } from './user'

export interface AccountVendor {
  _id: ObjectId
  created: Date
  name: string
  permissions: UserPermissionType[]
  removed?: boolean
  removedDate?: Date
  removedBy?: ObjectId
}

export interface AdminVendorOverview {
  _id: ObjectId
  name: string
  created: Date
  shipperAccountId: ObjectId
  users: Array<User>
  permissions: UserPermissionType[]
}

export const searchVendorsScheam = vObject({
  accountId: vObjectId(),
})
export type SearchVendorsRequest = vOutput<typeof searchVendorsScheam>

export const editVendorSchema = vObject({
  name: vString(),
  permissions: vArray(vPicklist(userPermissionTypes)),
})
export type EditVendorRequest = vOutput<typeof editVendorSchema>
export const createVendorSchema = vObject({
  accountId: vObjectId(),
  ...editVendorSchema.entries,
})
export type CreateVendorRequest = vOutput<typeof createVendorSchema>

export const createVendorUserSchema = vObject({
  email: vEmail,
  vendorId: vObjectId(),
})
export type CreateVendorUserRequest = vOutput<typeof createVendorUserSchema>

export interface VendorShipperAccountResponse {
  _id: ObjectId
  name: string
}

export const updateVendorCurrentShipperSchema = vObject({
  _id: vObjectId(),
})
export type UpdateVendorCurrentShipperRequest = vOutput<
  typeof updateVendorCurrentShipperSchema
>
